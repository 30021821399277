import React from "react";
import Layout from "../../Layout";
import SEO from "../../seo";
import "./style.scss";
export default function BusRoutes() {
  return (
    <Layout>
      <SEO
        title="Bus Routes | Chinmaya Vidyalaya Vasant Vihar"
        description="Download the available bus route details including the areas covered by school transport. Please note school transport is subject to availability of seats."
      />
      <div className="busroutes">
        <h1 className="heading">Bus Routes</h1>

        <p>
          You can apply for bus facility if your residence area lies within the
          area covered by school transport. However, please note that the school
          transport will only be provided subject to available routes and seats.
          The school does not guarantee or commit to provide transport facility.
        </p>

        <div className="busroutes__table">
          <div className="busroutes__table__row busroutes__table__row--heading">
            <div className="busroutes__table__col1">Bus Route</div>
            <div className="busroutes__table__col2">
              Areas Covered By School Buses
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 1</div>
            <div className="busroutes__table__col2">
              Chhatarpur, Tivoli Garden, Satbari, Chattarpur DLF
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 2</div>
            <div className="busroutes__table__col2">
              JNU, Chattarpur Metro Station, Ghitorni Metro, Sultanpur Metro
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 3</div>
            <div className="busroutes__table__col2">
              Tivoli Garden, Sai baba Mandir, Harpreet Ford, Kishangarh Car
              market, CNG Kishangarh
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 4</div>
            <div className="busroutes__table__col2">
              PushpVihar, Sheikh Sarai, Khanpur Depot, Saket Metro Station,
              Saidulajab, LadoSarai, PTS Colony,
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 5</div>
            <div className="busroutes__table__col2">
              Lajpat Nagar Ring Road, Ashram, Jungpura (Main Road), Kotla,
              Andrews Ganj Police Colony, Father Agnel School, Hauz Khas Market,
              Green Park, Deer Park, Vasant Vihar Depot, Munirka Anupam
              Restaurant
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 6</div>
            <div className="busroutes__table__col2">
              Nanak Pura Gurudwara, MotiBagh Under Fly over, Nivedita Kunj, R K
              Puram Sector 10, Mohan Singh Market, Budh Vihar Munirka, Munirka
              Vihar
            </div>
          </div>{" "}
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 7</div>
            <div className="busroutes__table__col2">
              E1 Vasant Kunj, E2 Vasant Kunj, C9 Vasant Kunj, CNG Vasant Kunj
            </div>
          </div>{" "}
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 8</div>
            <div className="busroutes__table__col2">
              Vasant Kunj D6, B1, B5, B10, B11, Masoodpur, Jal Board Vasant
              Kunj, Nelson Mandela Road (opp. Ambience Mall)
            </div>
          </div>{" "}
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 9</div>
            <div className="busroutes__table__col2">
              Malviya Nagar APJ School, Malviya Nagar Market, Adchini, Katwaria
              Sarai, JNU Main Gate
            </div>
          </div>{" "}
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 10</div>
            <div className="busroutes__table__col2">
              Radisson Hotel, Mahilpalpur
            </div>
          </div>{" "}
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 11</div>
            <div className="busroutes__table__col2">
              Mahilpalpur, Nangal Dewat Main Road, Choti Red Light, Vasant Kunj
              C1, C3
            </div>
          </div>{" "}
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 12</div>
            <div className="busroutes__table__col2">
              Mehrauli, Chattarpur 100 ft road, Chattarpur Temple, Vasant Kunj
              D1, D2, D3
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 13</div>
            <div className="busroutes__table__col2">
              Laxmi Bai Nagar, Sarojini Nagar Depto, Market, Kamal Cinema,
              Greenfield School Safdurjung, R.K. Puram Sector-1,3, RBI Colony,
              Indian Airlines Colony
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Sr. Route 14</div>
            <div className="busroutes__table__col2">
              Harpreet Ford, Kendriya Vidyalaya main road, Mother Dairy Vasant
              Kunj, Harijan Basti Vasant Kunj
            </div>
          </div>
        </div>

        <h3>
          JR Route Timing and Route in the Afternoon 12:00 p.m. (Morning Route
          same as Senior Route)
        </h3>

        <div className="busroutes__table">
          <div className="busroutes__table__row busroutes__table__row--heading">
            <div className="busroutes__table__col1">Bus Route</div>
            <div className="busroutes__table__col2">
              Areas Covered By School Buses
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Jr. Route 1</div>
            <div className="busroutes__table__col2">
              Vasant Kunj B10, Kishan Garh, Chattarpur Metro Station, Chattarpur
              Temple, Chattarpur Pahari, Tivoli, Satbari
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Jr. Route 2</div>
            <div className="busroutes__table__col2">
              D-6, Vasant Kunj, Nagal Dewat, E-1, E-2, Vasant Kunj, Mahipalpur,
              mata Chowk, Bharmaputra House
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Jr. Route 3</div>
            <div className="busroutes__table__col2">
              Budh Vihar Munirka, Ber Sarai, Katwariya Sarai, Adchini, Mehroli,
              Saket, Saidulajab Main Road, Khanpur Main road, PTS
            </div>
          </div>
          <div className="busroutes__table__row">
            <div className="busroutes__table__col1">Jr. Route 4</div>
            <div className="busroutes__table__col2">
              JNU, Purvanchal, Munirka DDA Flat, Mother Dairy Munirka, UDPI
              Restaurant, R.K Puram Sector- 1,4,5, Moti Bagh, Air India Colony
            </div>
          </div>
        </div>
        <br />
        <div>
          <strong>Note: </strong>
          <ul style={{ marginLeft: "20px" }}>
            <li>
              Senior Route Buses ply in the Morning (for Nur to XII) and
              Afternoon (for classes I to XII at 2.15 pm).
            </li>
            <li>Junior Route Buses (Only for Nur & Prep) ply at 12:00 pm.</li>
          </ul>
        </div>
        <br />
        <p>
          <strong>
            For any query, please contact: <br />
            Mr. Satender Bhandari - 8130480044, 9810878354
            {/* <br /> Mr. Prashant Srivastava - 9810878354. */}
          </strong>
        </p>
      </div>
    </Layout>
  );
}
